import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

//import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function FloorplansButton({handleClick}: any) {
  const classes = useStyles();

  return (
    <Tooltip title={'View Floorplans'} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} onClick={() => handleClick(2)}>
        <ViewQuiltIcon />
      </Fab>
    </Tooltip>
  );
}
