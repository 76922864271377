import { useEffect, useState } from 'react';
import jsonp from 'jsonp';
import config from '../../config';

export default function useTourStatus(tourStr: string | undefined ) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({'status': 'completed'});

  let apptUrl = config.tsApi +  '/virtualTour/rooms/' + tourStr + '/status';
  //apptUrl = 'http://127.0.0.1:5500/' + apptUrl;

  let timer:any;

  function getStatus() {
    if (items.status != 'in-progress'){
      fetch(apptUrl)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);

            if (result.status == 'in-progress'){
              clearInterval(timer);
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }

  useEffect(() => {
    timer = setInterval(() => {
      getStatus();
    }, 3000);
    
    
  }, [tourStr]);

  return items;
}
