import React from 'react';
import { styled, createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//import useTourVideoSrc from '../../hooks/useTourVideoSrc/useTourVideoSrc';
import FloorplanFilterBar from '../FloorplanFilterBar/FloorplanFilterBar';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface FloorplanStripProps {
  theme:any;
  currentFloorplan: any;
  propertyFloorplans: Array<any>;
  onFloorplanClick: (newValue: any) => void;
  onDrawerClose: (newValue: boolean) => void;
  open: boolean;
}

export default function FloorplanStrip({theme, currentFloorplan, propertyFloorplans, onFloorplanClick, onDrawerClose, open }: FloorplanStripProps) {
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const handleClickAway = () => {
    setOpenDrawer(false);
    onDrawerClose(false);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    onDrawerClose(false);
  };

  const Container = styled('div')({
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '24px 0 0 0',
    alignItems: 'center',
  });
  
  const FloorplanContainer = styled('div')({
    width: '148px',
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    '& p': {
      fontWeight: 'bold',
      margin: '4px 0 0',
      '& em': {
        fontWeight: 'normal'
      }
    }
  });
  
  const FloorplanImageContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '4px solid transparent',
    width: '148px',
    height: '100px',
    background: '#fff',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    '&:hover': {
      border: '4px solid',
      borderColor: theme.palette.primary.main
    }
  });
  
  const EmptyResults = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '30px',
    fontSize: '30px',
    textAlign: 'center'
  });
  
  const useStyles = makeStyles({
    root: {
      position: 'relative',
    },
    active: {
      borderColor: theme.palette.primary.main
    },
    fpContainer: {
    },
    fpDrawer: {
      width: '360px',
      // '& > div > div > div': {
      //   margin: '10px'
      // }
    },
    fpWrapper: {
      ['@media (max-width:767px)']: {
        display: 'none'
      },
    },
    drawerBtn: {
      ['@media (min-width:768px)']: {
        display: 'none'
      },
    },
    drawerWrapper: {
      width: '360px',
      display: 'flex',
      flexDirection: 'column',
      padding: '12px'
    },
    fpsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        margin: '10px'
      }
    },
    closeIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: '1',
      margin: '0 !important'
    }
  });
  const classes = useStyles();
  const defualtThumb = '/img/fp_default_thumb.png';

  // floorplan filters
  const [filteredFloorplans, setFilteredFloorplans] = React.useState(propertyFloorplans);
  const [floorplanFilters, setFloorplanFilters] = React.useState(['any', 'any', '2D']);
  const handleFloorplanFiltersChange = (newValue: Array<string>) => {
    setFloorplanFilters(newValue);

    let filteredFloorplans = propertyFloorplans.filter(function(floorplan: any){
      let bedFilter = false;
      let bathFilter = false;

      if (newValue[0] == 'any'){
        bedFilter = true;
      } else if (newValue[0] == '1' && Math.round(floorplan.bedRooms) == 1){
        bedFilter = true;
      } else if (newValue[0] == '2' && Math.round(floorplan.bedRooms) == 2){
        bedFilter = true;
      } else if (newValue[0] == '3' && Math.round(floorplan.bedRooms) >= 3){
        bedFilter = true;
      } else if (newValue[0].toLowerCase() == 's' && floorplan.bedRooms.toLowerCase() == 's'){
        bedFilter = true;
      }

      if (newValue[1] == 'any'){
        bathFilter = true;
      } else if (newValue[1] == '1' && Math.round(floorplan.bathRooms) == 1){
        bathFilter = true;
      } else if (newValue[1] == '2' && Math.round(floorplan.bathRooms) >= 2){
        bathFilter = true;
      }

      return (bedFilter && bathFilter);
    })

    setFilteredFloorplans(filteredFloorplans);
  }

  //if (filteredFloorplans.length < 1) return null;

  const floorplans = filteredFloorplans.map((floorplan, index) => {
    const floorplanImage = (floorplan.floorPlanImages != undefined && floorplan.floorPlanImages.length > 0) ? floorplan.floorPlanImages[0].src.replace('%s', '300x300') : defualtThumb;
    //const tipText = 'Bed: ' + floorplan.bedRooms + ', Bath: ' + floorplan.bathRooms;
    const bedLabel = (floorplan.bedRooms == 'S') ? 'studio' : floorplan.bedRooms + 'bd';
    const caption = bedLabel + ', ' + floorplan.bathRooms + 'ba';
    const sqft = floorplan.minimumSquareFeet + ((floorplan.maximumSquareFeet != floorplan.minimumSquareFeet && floorplan.maximumSquareFeet != 0) ? ' - ' + floorplan.maximumSquareFeet : '') + 'sqft';
    const priceRange = floorplan.rentRange;
    return (
      <FloorplanContainer className={classes.fpContainer} key={index} onClick={() => onFloorplanClick(floorplan) }>
        <FloorplanImageContainer className={clsx({ [classes.active]: floorplan.id == currentFloorplan.id })}>
          <img src={floorplanImage} key={floorplan.src} />
        </FloorplanImageContainer>
        <p>{floorplan.name}<br /><em>{caption}</em>, <em>{sqft}</em><br /><em>{priceRange}</em></p>
      </FloorplanContainer>
    )    
  })

  const emptyResults = <EmptyResults><SearchIcon style={{ fontSize: 60 }} />No results found</EmptyResults>
  const floorplansDrawer = <ClickAwayListener onClickAway={handleClickAway}><div className={classes.drawerWrapper}><div className={classes.closeIcon}><IconButton aria-label="close" onClick={handleClickAway}><HighlightOffIcon fontSize="small" /></IconButton></div><FloorplanFilterBar onFloorplanFiltersChange={handleFloorplanFiltersChange} onHandleDrawerClick={handleDrawerOpen} floorplanData={filteredFloorplans} floorplanFilters={floorplanFilters} /> {filteredFloorplans.length > 0 ? <div className={classes.fpsWrapper}>{floorplans}</div> : emptyResults}</div></ClickAwayListener>

  return (
    <div>
      {/* <Container className={classes.fpWrapper}>
        {floorplanData.length > 0 ? floorplans : emptyResults}
      </Container> */}
          
      <Drawer
        className={classes.fpDrawer}
        anchor="right"
        open={open}
      >
        {floorplansDrawer}
      </Drawer>
    </div>
  );
}
