import { useEffect, useState } from 'react';
import jsonp from 'jsonp';
import config from '../../config';

export default function useApplyNow(tourStr: string) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});

  const apptUrl = config.tsApi +  '/virtualTour/rooms/' + tourStr + '/applyNowUrl';

  useEffect(() => {
    if (tourStr == '' || tourStr == undefined) return;

    fetch(apptUrl)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    
  }, [tourStr]);

  return items;
}
