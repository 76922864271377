import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import clsx from 'clsx';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import TourVideo from '../TourVideo/TourVideo';
import TourStrip from '../TourStrip/TourStrip';
import FloorplanMain from '../FloorplanMain/FloorplanMain';
import FloorplanImageNav from '../FloorplanImageNav/FloorplanImageNav';
import FloorplanStrip from '../FloorplanStrip/FloorplanStrip';
import FloorplanFilterBar from '../FloorplanFilterBar/FloorplanFilterBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function Room({theme, propertySummary, propertyFloorplans, currentTab, currentTour, currentFloorplan, currentFloorplanImage, handleFloorplanImageChange, handleTabChange, drawerOpen, isAgent, handleSharingScreen, handleDisconnect}:any) {
  const Container = styled('div')({
    position: 'relative',
    height: '100%',
    display: 'flex',
    padding: '0',
    ['@media (max-width:767px)']: {
      padding: '0'
    }
  });

  console.log('Room');
  
  const MainParticipantContainer = styled('div')(({ theme }) => ({
    flex: 1,
    position: 'relative'
  }));
  
  const useStyles = makeStyles({
    tourStrip: {
      display: 'flex'
    },
    webLink: {
      padding: '0 30px',
      fontWeight: 500,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      color: '#fff',
      textDecoration: 'none'
    },
    tabBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    isToursActive: {
      display: 'none'
    },
    isFloorplansActive: {
      display: 'none'
    },
    hide: {
      display: 'none'
    },
    noPadding: {
      padding: '0px'
    }
  });
  const classes = useStyles();

  const isSharingScreen = false;

  // tours
  const isToursEmpty = (propertySummary.tours == null) ? true : false;

  // floorplans
  const isFloorplansEmpty = (propertyFloorplans.floorplans !== null && propertyFloorplans.floorplans.length < 1) ? true : false;

  const [open, setOpen] = React.useState(true);
  // console.log('open', open);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ParticipantStrip hideStrip={isSharingScreen} handleDisconnect={handleDisconnect} />
      <TabPanel value={currentTab} index={0}>
        <Container className={clsx({ [classes.noPadding]: isSharingScreen })} id="main-participant-container">
          <MainParticipantContainer>
            <MainParticipant handleSharingScreen={handleSharingScreen} />
          </MainParticipantContainer>
        </Container>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Container>
          <MainParticipantContainer>
            {isToursEmpty ? (
              <h2>Sorry, no floor plans available.</h2>
            ) : (
              <TourVideo tourData={propertySummary.tours} currentTour={currentTour} open={open} />
            )}
          </MainParticipantContainer>
        </Container>
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <Container>
          {isFloorplansEmpty ? (
            <h2>Sorry, no floor plans available.</h2>
          ) : (
            <MainParticipantContainer>
              <FloorplanMain theme={theme} floorplanData={propertyFloorplans.floorplans} currentFloorplan={currentFloorplan} currentFloorplanImage={currentFloorplanImage} />
              <FloorplanImageNav theme={theme} floorplanData={propertyFloorplans.floorplans} currentFloorplan={currentFloorplan} currentFloorplanImage={currentFloorplanImage} onFloorplanImageChange={handleFloorplanImageChange} />
            </MainParticipantContainer>
          )}
        </Container>
      </TabPanel>
    </div>
  );
}
