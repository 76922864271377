import React from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface FloorplanVideoProps {
  theme:any;
  floorplanData: Array<any>;
  currentFloorplan: any;
  currentFloorplanImage: number;
}

export default function FloorplanMain({ theme, floorplanData, currentFloorplan, currentFloorplanImage }: FloorplanVideoProps) {
  const Container = styled('div')({
    position: 'relative',
    display: 'flex',
    background: '#141414',
    height: 'calc(100vh - 190px)',
    ['@media (max-width:767px)']: {
      height: 'calc(100vh - 190px - 119px)',
    },
    '& iframe': {
      display: 'none',
      width: '100%',
      height: '100%',
      border: '0'
    },
    '& img': {
      display: 'none',
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  });
  
  const useStyles = makeStyles({
    floorplanFrame: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    active: {
      display: 'block !important'
    }
  });

  const classes = useStyles();
  const defualtThumb = '/img/fp_default_thumb.png';
  let navCount = 0;

  if (floorplanData.length < 1) return <Container><h2>Sorry, no floor plans available.</h2></Container>;

  const floorplansMedia = (currentFloorplan.floorPlanMedia == null) ? null : currentFloorplan.floorPlanMedia.map((floorplan:any, index:number) => {
    navCount++;
    let iframeSrc = (floorplan.src.indexOf('youtube.com/watch') != -1)  ? floorplan.src.replace('watch?v=', 'embed/') : floorplan.src;

    return (
      <iframe src={iframeSrc} key={iframeSrc} className={clsx({ [classes.active]: index == currentFloorplanImage })} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
    )
  })

  const floorplansImages = (currentFloorplan.floorPlanImages == null) ? null : currentFloorplan.floorPlanImages.map((floorplan:any, index:number) => {
    const floorplanImage = (floorplan.src != '') ? floorplan.src.replace('%s', '800x800') : defualtThumb;
    const imageCount = index + navCount;
    return (
      <img src={floorplanImage} key={floorplan.src} className={clsx({ [classes.active]: imageCount == currentFloorplanImage })} />
    )
  })

  return (
    <Container id="floorplan-images">
      { floorplansMedia }
      { floorplansImages }
    </Container>
  );
}
