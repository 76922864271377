import React, { ChangeEvent, useState }  from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountdownTimer from '../JoinButton/JoinButton';
import classes from '*.module.css';
import useTourService from '../../hooks/useTourService/useTourService';
// import useTourStatus from '../../hooks/useTourStatus/useTourStatus';
import JoinButton from '../JoinButton/JoinButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import useTourStatus from '../../hooks/useTourStatus/useTourStatus';
import useTourServiceProspect from '../../hooks/useTourService/useTourServiceProspect';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center'
});

export default function LocalVideoPreview({theme, propertySummary, appointment, roomDetails, isAgent, confirmationId, setUserIdentity}:any) {
  const useStyles = makeStyles({
    column: {
      flexDirection: 'column',
      margin: '3vh auto',
      maxWidth: '90vw',
      fontSize: '16px',
      ['@media (min-width:1200px)']: {
        maxWidth: '70vw',
      },
      '& h2': {
        margin: '0 0 2vh',
        fontWeight: '400',
        ['@media (min-width:768px)']: {          
          fontSize: '32px',
        },
      },
      '& p': {
        margin: '15px 0 0 0'
      }
    },
    fieldColumn: {
      flexDirection: 'column',
      margin: '1vh auto',
      maxWidth: '90vw',
      fontSize: '16px',
      ['@media (min-width:1200px)']: {
        maxWidth: '70vw',
      }
    },
    infoContainer: {
      display: 'flex',
      padding: '0 0 3vh',
      ['@media (min-width:768px)']: {
        padding: '0 0 6vh',
      }
    },
    videoPreview: {
      //display: 'flex',
      marginRight: '30px',
      maxHeight: '500px',
      // [theme.breakpoints.up('md')]: {
      //   backgroundColor: 'red',
      // },
      ['@media (max-width:767px)']: {
        display: 'none'
      }
    },
    apptInfo: {
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      ['@media (min-width:768px)']: {          
        whiteSpace: 'nowrap',
      },
      '& h4': {
        fontSize: '24px',
        margin: '0'
      },
      '& > div': {
        display: 'flex',
        textAlign: 'left',
        padding: '15px'
      },
      '& hr': {
        width: '100%',
        height: '1px',
        border: 'none',
        borderTop: '1px solid #8e8e8e'
      },
      '& svg': {
        fontSize: '30px',
        marginRight: '20px',
        fill: theme.palette.primary.main
      }
    },
    buttonContainer: {
      margin: '20px 0 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      flexDirection: 'column',
      ['@media (min-width:768px)']: {
        alignItems: 'center',
        flexDirection: 'row'
      }
    },
    loadingSpinner: {
      margin: '50px auto',
      display: 'block',
      color: '#fff'
    },
    joinProgress: {
      display: 'none'
    },
    waiting: {
      margin: '0 0 0 10px',
      display: 'block',
      color: '#fff',
      width: '20px !important',
      height: '20px !important'
    },
    joinBtn: {
      ['@media (min-width:768px)']: {          
        fontSize: '1.2rem',
        padding: '14px 30px'
      }
    },
    joinField: {
      marginBottom: '10px',
      height: '60px',
      width: '320px',
      '& input': {
        height: '60px',
        padding: '0 14px'
      },
      ['@media (min-width:768px)']: {
        marginBottom: '0',
        marginRight: '20px',
      }
    },
    tourBtnDisabled: {
      opacity: '.3',
      pointerEvents: 'none',
    },
    countdownContainer: {
      marginTop: '40px'
    },
    joinForm: {
      display: 'flex',
      justifyContent: 'center'
    }
  });
  const classes = useStyles();
  const { AppointmentToken } = useParams<{ AppointmentToken: string }>();
  const [prospectName, setProspectName] = useState('');

  const tourStatus = useTourStatus(AppointmentToken);

  const { localTracks, isConnecting, connect } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;
  const videoEl = (videoTrack) ? <VideoTrack track={videoTrack} isLocal /> : null;

  if (propertySummary.length < 1) return <CircularProgress className={classes.loadingSpinner} />;

  const apptDate = moment(appointment.apptStartTime).format('dddd, MMMM D, YYYY');
  const apptStartTime = moment(appointment.apptStartTime).format('h:mm A');
  const apptEndTime = moment(appointment.apptEndTime).format('h:mm A');

  const name = (isAgent) ? 'Leasing Agent' : appointment.firstName;
  const welcomeMsg = (isAgent) ? 'start the tour when you are ready.' : 'the leasing agent will start the tour soon.';
  const tourBtnInActive = (tourStatus.status != 'in-progress') ? true : false;
  const joinBtnTxt = (isAgent) ? 'Start the Tour' : (tourBtnInActive) ? 'Waiting for Agent...' : 'Join the Tour';

  if (isAgent) {
    setUserIdentity('Leasing Agent');
  }


  const leadChannel = propertySummary.leadChannelAttributes.find((item:any) => item.leadChannelName === 'Appointment Widget');
  const match = leadChannel.phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  const phoneNumber = '(' + match[1] + ') ' + match[2] + '-' + match[3];

  let typing = false;
  let nameTimeout:NodeJS.Timeout;

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    typing = true;
    clearTimeout(nameTimeout);

    nameTimeout = setTimeout(function(){
      setProspectName(event.target.value);
    }, 100);
  };

  return (
    <Container className={classes.column}>
      <Container className={classes.infoContainer}>
        <div className={classes.videoPreview}>{videoEl}</div>
        <Container className={classes.apptInfo}>
          <div>
            <LocationCityIcon />
            <div>
              <h4>{propertySummary.name}</h4>
              <p>
                {propertySummary.address.address1}<br />
                {propertySummary.address.cityname}, {propertySummary.address.statecode} {propertySummary.address.postalcode}<br />
                {phoneNumber}
              </p>
            </div>
          </div>
          <hr />
          <div>
            <CalendarTodayIcon />
            <div>
              <h4>{apptDate}</h4>
              <p>{apptStartTime} - {apptEndTime}</p>
            </div>
          </div>
        </Container>
      </Container>
      
      <h2>Welcome, {welcomeMsg}</h2>

      <Container className={classes.fieldColumn}>
        <Container className={classes.buttonContainer}>
          {isAgent != true ? <TextField
            label="Enter Your Name"
            className={classes.joinField}
            onChange={handleNameChange}
            variant="outlined" 
            autoFocus={true}
            required
          /> : null}

          <JoinButton propertySummary={propertySummary} tourStatus={tourStatus} isAgent={isAgent} name={prospectName} tourStr={AppointmentToken} setUserIdentity={setUserIdentity}></JoinButton>
        </Container>
      </Container>
    </Container>
  );
}
