import React from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import { createStyles, styled, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import usePropertyUnits from '../../../hooks/usePropertyUnits/usePropertyUnits';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const DarkBackdrop = styled(Backdrop)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)'
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      outline: 'none',
      '& :focus': {
        outline: 'none'
      },
      ['@media (min-width:769px)']: {
        transform: 'scale(1.5)',
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        margin: '0 10px',
        padding: '7px 30px'
      }
    },
    confirmContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
      '& button': {
        margin: '0 10px',
        padding: '7px 30px'
      },
      '& input': {
        marginRight: '5px'
      }
    }
  })
);

export default function EndCallButton({isAgent, propertySummary, propertyFloorplans, handleEndScreenSharing}:any) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [open, setOpen] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [selectedUnits, setSelectedUnits] = React.useState([]);
  // const [selectedUnitId, setSelectedUnitId] = React.useState('');
  const [comments, setComments] = React.useState('');    
  const { AppointmentToken } = useParams<{ AppointmentToken: string }>();

  // const propertyUnits:any = usePropertyUnits(propertySummary.id);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (answer: boolean) => {
    setOpen(false);

    if (answer){
      room.disconnect(); 
      handleEndScreenSharing();

      if (isAgent && confirmed){
        const apptUrl = config.tsApi +  '/virtualTour/rooms/' + AppointmentToken + '/event';
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ unitId: selectedUnits.join(','), comments: comments })
        };

        fetch(apptUrl, requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
              console.log('event response', result);
            },
            (error) => {
              console.log('error', error);
            }
          )
      }
    }
  };

  const endTourMsg = (isAgent) ? 'Are you ready to end the tour?' : 'Are you sure you want to leave?';

  // const handleSelectedUnits = (e: any, values: any) => {
  //   setSelectedUnits(values.map((v: any) => v.unitPartnerID));
  // };

  // const handleComments = (e: any) => {
  //   setComments(e.target.value);
  // };

  // const units = (propertyUnits.length < 1) ? [] : propertyUnits.filter((u:any) => {
  //   const newUnit = u;
  //   const matchingFloorplan = (propertyFloorplans.floorplans !== null) ? propertyFloorplans.floorplans.find((f: { name: any; }) => f.name === u.floorPlanName) : undefined;

  //   // if (matchingFloorplan !== undefined && u.leaseStatus === 'AVAILABLE_READY' && u.active === true) {
  //   if (matchingFloorplan !== undefined) {
  //     console.log('matchingFloorplan', matchingFloorplan.name, newUnit.unitNumber, newUnit.available);
  //     newUnit.floorplan = matchingFloorplan.name;

  //     return newUnit;
  //   }
  // });

  // units.sort((a: any, b: any) => (a.floorplan > b.floorplan) ? 1 : -1);

  const handleConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(event.target.checked);
  };

  return (
    <div>
      <Tooltip title={'End Call'} onClick={handleOpen} placement="top" PopperProps={{ disablePortal: true }}>
        <Fab className={classes.fab} color="secondary">
          <CallEnd />
        </Fab>
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {handleClose(false)}}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={DarkBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{endTourMsg}</h2>
            <div className={classes.buttonContainer}>
              <Button 
                variant="outlined" 
                size="large" 
                onClick={() => {handleClose(false)}}>
                  No
              </Button>
              <Button 
                variant="contained" 
                size="large" 
                onClick={() => {handleClose(true)}}>
                  Yes
              </Button>
            </div>
            {isAgent && (
              <>
                <div className={classes.confirmContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmed}
                        onChange={handleConfirm}
                        color="primary"
                        name="confirmed"
                      />
                    }
                    label="Confirm Tour Ended"
                  />
                </div>
              </>
            )}
            {/* {confirmed && (
              <>
                <Autocomplete
                  id="selected-units"
                  options={units}
                  multiple
                  onChange={handleSelectedUnits}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) => option.unitNumber}
                  groupBy={(option) => option.floorplan}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                      />
                      {option.unitNumber}
                    </React.Fragment>
                  )}
                  size="small"
                  style={{ marginTop: '20px' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Units Shown" placeholder="" />
                  )}
                />

                <div className={classes.confirmContainer}>
                  <TextField
                    id="comments"
                    label="Comments"
                    multiline
                    fullWidth
                    defaultValue=""
                    onChange={handleComments}
                  />
                </div>
              </>
            )} */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
