import { useState, useCallback, useRef } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useFlipCameraToggle() {

  const [isFlipAvailable, setIsFlipAvailable] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();


  navigator.mediaDevices.enumerateDevices().then(devices => {
    const videoInput = devices.filter(device => device.kind === 'videoinput');
    if (videoInput.length > 1){
      setIsFlipAvailable(true);
    }
  });
  
  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  const toggleFlipCamera = useCallback(() => {
    if (videoTrack) {
      if (localParticipant) {
        const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
        localParticipant.emit('trackUnpublished', localTrackPublication);
      }
      videoTrack.stop();
      
      var el = document.getElementById('root');

      if (isFlipped) {
        getLocalVideoTrack('').then((track: LocalVideoTrack) => {
          if (localParticipant) {
            setTimeout(function(){
              localParticipant.publishTrack(track);
            }, 100);
          }
          setIsFlipped(false);
          if (el) el.classList.remove('is-flipped');
        });
      } else {
        getLocalVideoTrack('environment').then((track: LocalVideoTrack) => {
          if (localParticipant) {
            setTimeout(function(){
              localParticipant.publishTrack(track);
            }, 100);
          }
          setIsFlipped(true);
          if (el) el.classList.add('is-flipped');
        });
      }
    }
  }, [videoTrack, isFlipped, localParticipant, getLocalVideoTrack]);

  return [!!isFlipped, isFlipAvailable, toggleFlipCamera] as const;
}
