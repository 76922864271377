import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useTourService from './hooks/useTourService/useTourService';
import useApplyNow from './hooks/useApplyNow/useApplyNow';
import rptracker from './rptracker';
import moment from 'moment';
//import useTourStatus from './hooks/useTourStatus/useTourStatus';


// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.

// audio: true,
// maxAudioBitrate: 16000, //For music remove this line
// video: { 
//   height: 720, 
//   frameRate: 24, 
//   width: 1280 
// }

const connectionOptions: ConnectOptions = {
  // bandwidthProfile: {
  //   video: {
  //     mode: 'grid',
  //     dominantSpeakerPriority: 'standard',
  //     renderDimensions: {
  //       high: { height: 1080, width: 1920 },
  //       standard: { height: 720, width: 1280 },
  //       low: { height: 90, width: 160 },
  //     },
  //   },
  // },
  // dominantSpeaker: true,
  // maxAudioBitrate: 12000,
  // networkQuality: { local: 3, remote: 3 },
  // preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
  audio: true,
  maxAudioBitrate: 12000,
  video: { 
    height: 720, 
    frameRate: 24, 
    width: 1280 
  }
};

sessionStorage.removeItem('joinedTime');

const VideoApp = () => {
  const { error, setError } = useAppState();
  const { AppointmentToken } = useParams<{ AppointmentToken: string }>();
  const [ userIdentity, setUserIdentity] = useState('');
  //const tourStatus = useTourStatus(AppointmentToken);
  const tourService = useTourService(AppointmentToken, null);
  const applyNow = useApplyNow(AppointmentToken);

  window.onbeforeunload = function(event:any){
    console.log(tourService.roomDetails.identity + ' has disconnected (onbeforeunload)');

    const joinedTime = sessionStorage.getItem('joinedTime');

    if (joinedTime != null){
      const startTime = moment(joinedTime);
      const endTime = moment();
      const duration = endTime.diff(startTime);
  
      rptracker.track('Left Room', 
        {
          property_key: tourService.roomDetails.pKey,
          property_name: tourService.propertySummary.name,
          confirmation_id: tourService.roomDetails.confirmationId,
          identity: tourService.roomDetails.identity,
          room_id: tourService.roomDetails.roomId,
          is_agent: tourService.isLeasingAgent,
          start_time: startTime.format(),
          end_time: endTime.format(),
          duration: duration,
          user_closed_browser: true
        }
      );

      sessionStorage.removeItem('joinedTime');
    }
  };

  const handleDisconnect = () => {
    //console.log(tourService.roomDetails.identity + ' has disconnected');
    console.log(userIdentity + ' has disconnected');

    const joinedTime = sessionStorage.getItem('joinedTime');

    if (joinedTime != null){
      const startTime = moment(joinedTime);
      const endTime = moment();
      const duration = endTime.diff(startTime);
  
      rptracker.track('Left Room', 
        {
          property_key: tourService.roomDetails.pKey,
          property_name: tourService.propertySummary.name,
          confirmation_id: tourService.roomDetails.confirmationId,
          identity: userIdentity,
          room_id: tourService.roomDetails.roomId,
          is_agent: tourService.isLeasingAgent,
          start_time: startTime.format(),
          end_time: endTime.format(),
          duration: duration,
          user_closed_browser: false
        }
      );

      sessionStorage.removeItem('joinedTime');
    }

    // setCurrentTour(newValue);
    // setOpen(false);
  }

  return (
    <VideoProvider options={connectionOptions} onError={setError} onDisconnect={handleDisconnect}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App tourService={tourService} applyNow={applyNow} appointmentToken={AppointmentToken} setUserIdentity={setUserIdentity} />
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path="/">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/join/:AppointmentToken">
            <VideoApp  />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AppStateProvider>
    </Router>  
  </MuiThemeProvider>,
  document.getElementById('root')
);
