import React from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface FloorplanVideoProps {
  theme:any;
  floorplanData: Array<any>;
  currentFloorplan: any;
  currentFloorplanImage: number;
  onFloorplanImageChange: (newValue: number) => void;
}

export default function FloorplanImageNav({ theme, floorplanData, currentFloorplan, currentFloorplanImage, onFloorplanImageChange }: FloorplanVideoProps) {  
  const Container = styled('div')({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
    flexWrap: 'wrap',
    '& button': {
      border: 'none',
      width: '20px',
      height: '20px',
      margin: '0 5px',
      padding: '0',
      background: '#999',
      borderRadius: '10px',
    }
  });
  
  const useStyles = makeStyles({
    floorplanFrame: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    active: {
      background: theme.palette.primary.main + ' !important'
    }
  });

  const classes = useStyles();
  let navCount = 0;

  if (floorplanData.length < 1) return null;

  const mediaNav = (currentFloorplan.floorPlanMedia == null) ? null : currentFloorplan.floorPlanMedia.map((floorplan:any, index:number) => {
    navCount++;
    return (
      <button key={floorplan.src} onClick={() => onFloorplanImageChange(index) } className={clsx({ [classes.active]: index == currentFloorplanImage })}></button>
    );
  })
  
  const imageNav = (currentFloorplan.floorPlanImages == null) ? null : currentFloorplan.floorPlanImages.map((floorplan:any, index:number) => {
    const imageCount = index + navCount;
    return (
      <button key={floorplan.src} onClick={() => onFloorplanImageChange(imageCount) } className={clsx({ [classes.active]: imageCount == currentFloorplanImage })}></button>
    )
  })

  return (
    <Container>
      { mediaNav }
      { imageNav }
    </Container>
  );
}
