import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import rptracker from '../../rptracker';
import config from '../../config';

export default function JoinButton({tourStr, propertySummary, name, tourStatus, isAgent, setUserIdentity}:any) {
  const Container = styled('div')({
    position: 'relative',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  });
  
  const useStyles = makeStyles({
    column: {
      flexDirection: 'column',
      margin: '1vh auto',
      maxWidth: '90vw',
      fontSize: '16px',
      ['@media (min-width:1200px)']: {
        maxWidth: '70vw',
      }
    },
    buttonContainer: {
      margin: '20px 0 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    joinProgress: {
      display: 'none'
    },
    waiting: {
      margin: '0 0 0 10px',
      display: 'block',
      color: '#fff',
      width: '20px !important',
      height: '20px !important',
      '& svg': {
          fill: '#fff'
      }
    },
    joinBtn: {
      height: '60px',
      ['@media (min-width:768px)']: {
        fontSize: '1.2rem',
        padding: '14px 30px'
      }
    },
    tourBtnDisabled: {
      opacity: '.3',
      pointerEvents: 'none',
    },
    countdownContainer: {
      marginTop: '40px'
    }
  });

  const classes = useStyles();
  const { localTracks, isConnecting, connect } = useVideoContext();
  const tourBtnInActive = (isAgent) ? false : (!isAgent && (tourStatus.status != 'in-progress' || name == undefined || name == '')) ? true : false;
  const waiting = (isAgent) ? false : (!isAgent && (tourStatus.status != 'in-progress')) ? true : false;
  const joinBtnTxt =  (isAgent) ? 'Start the Tour' : (tourStatus.status == 'in-progress') ? 'Join the Tour' : 'Waiting for Agent...';

  const leadChannel = propertySummary.leadChannelAttributes.find((item:any) => item.leadChannelName === 'Appointment Widget');
  const match = leadChannel.phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  const joinRoom = () => {
    const apptUrl = config.tsApi +  '/virtualTour/rooms/' + tourStr + '/?name=' + name;
    const requestOptions = {
      method: 'POST'
    };

    fetch(apptUrl, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          connect(result.token);

          const startTime = moment().format();
          sessionStorage.setItem('joinedTime', startTime);
          setUserIdentity(name);

          rptracker.track('Joined Room', 
            {
              property_key: result.pKey,
              property_name: propertySummary.name,
              confirmation_id: result.confirmationId,
              identity: name,
              room_id: result.roomId,
              is_agent: isAgent,
              start_time: startTime
            }
          );
        },
        (error) => {
          console.log('error', error);
        }
      )
  };

  return (
    <Button 
      variant="contained" 
      color="primary" 
      size="large" 
      onClick={joinRoom} 
      className={clsx(classes.joinBtn, { [classes.tourBtnDisabled]: tourBtnInActive })}>
        {joinBtnTxt} 
        <CircularProgress className={clsx(classes.joinProgress, { [classes.waiting]: waiting })} />
    </Button>
  );
}
