import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import Tooltip from '@material-ui/core/Tooltip';

import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
  })
);

export default function ToggleFlipCameraButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isFlipped, isFlipAvailable, toggleFlipCamera] = useFlipCameraToggle();
  //const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  //const { room } = useVideoContext();

  //console.log('isFlipped', isFlipped);

  let tooltipMessage = "Flip Camera";

  // navigator.mediaDevices.enumerateDevices().then(gotDevices);

  const deviceIds:Array<any> = [];

  // function gotDevices(mediaDevices:Array<any>){
  //   let count = 1;

  //   const devices = mediaDevices.map((device, index) => {
  //     if (device.kind === 'videoinput'){
  //       //console.log('mediaDevice', device);
  //       const label = device.label || `Camera ${count++}`;
  //       deviceIds.push(device.deviceId);
  //       //console.log('device label', label);
  //     }
  //   })
  // }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      //style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <div>
        <Fab className={classes.fab} onClick={() => { toggleFlipCamera() }}>
          <FlipCameraIosIcon />
        </Fab>
      </div>
    </Tooltip>
  );
}
