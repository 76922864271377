import React from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useTourVideoSrc from '../../hooks/useTourVideoSrc/useTourVideoSrc';

const Container = styled('div')({
  position: 'relative',
  display: 'flex'
});

const TourContainer = styled('div')({
  position: 'relative',
  width: '100%',
  background: '#141414',
  height: 'calc(100vh - 190px)',
  ['@media (max-width:767px)']: {
    height: 'calc(100vh - 190px - 119px)',
  },
});

const useStyles = makeStyles({
  tourFrame: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    border: '0',
  },
  active: {
    // pointerEvents: 'none'
  }
});

interface TourVideoProps {
  tourData: Array<any>;
  currentTour: number;
  open: boolean;
}

export default function TourVideo({ tourData, currentTour, open }: TourVideoProps) {
  const classes = useStyles();

  if (tourData == null) return <Container><h2>Sorry, no tours available.</h2></Container>;

  const separator = tourData[currentTour].src.indexOf('?') !== -1 ? '&' : '?';
  const tourVideoSrc = tourData[currentTour].src + separator + 'play=1&qs=1&wmode=opaque';

  // var tourvideoStyle = (open) ? 'pointer-events: none' : '';
  // var el = document.getElementById('tourvideo');
  // if (el != null){
  //   console.log('found tourvideo');
  //   el.setAttribute('style', tourvideoStyle);
  // }

  return (
    <Container>
        <TourContainer>
          <iframe id="tourvideo" className={clsx(classes.tourFrame, {[classes.active]: open })} src={tourVideoSrc}></iframe>
        </TourContainer>
    </Container>
  );
}
