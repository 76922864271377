import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';
import { createSocket } from 'dgram';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import MenuSmall from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import PeopleIcon from '@material-ui/icons/People';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import TourStrip from '../TourStrip/TourStrip';
import FloorplanStrip from '../FloorplanStrip/FloorplanStrip';

export default function MenuBar({theme, uiSettings, propertySummary, propertyFloorplans, applyNow, currentTab, updateTab, handleTabChange, currentTour, handleTourClick, currentFloorplan, handleFloorplanClick, handleMeetingButtonClick, handleTourDrawerOpen, handleTourDrawerClose, tourOpen, handleFpDrawerOpen, handleFpDrawerClose, fpOpen}:any) {
  if (!('tourRoomBackground' in uiSettings)) {
    uiSettings.tourRoomBackground = 'Light';
  }
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        // backgroundColor: theme.palette.background.default,
        backgroundColor: (uiSettings.tourRoomBackground === 'Dark') ? '#111' : '#fff',
        color: (uiSettings.tourRoomBackground === 'Dark') ? '#fff' : '#303030',
      },
      tourToolbar: {
        justifyContent: 'space-between'
      },
      tourToolbarLinks: {
        display: 'flex',
        alignItems: 'center',
        ['@media (max-width:1023px)']: {
          display: 'none'
        },
      },
      tourToolbarMobileLInks: {
        ['@media (min-width:1024px)']: {
          display: 'none'
        },
      },
      webLink: {
        marginLeft: theme.spacing(1),
        ['@media (max-width:1023px)']: {
          display: 'none'
        }
      },
      form: {
        display: 'flex',
        alignItems: 'center',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
      loadingSpinner: {
        marginLeft: '1em',
      },
      displayName: {
        marginLeft: '2.2em',
        minWidth: '200px',
        fontWeight: 600,
      },
      isRoomActive: {
        //display: 'none'
      },
      logo: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          maxHeight: '50px',
          marginRight: '20px'
        }
      },
      isToursActive: {
        display: 'none'
      },
      isFloorplansActive: {
        display: 'none'
      }
    })
  );
  const classes = useStyles();
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 // floorplans
  const [filteredFloorplans, setFilteredFloorplans] = React.useState(propertyFloorplans.floorplans);
  const isFloorplansEmpty = (propertyFloorplans.floorplans !== null && propertyFloorplans.floorplans.length < 1) ? true : false;

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}`));
    }
    getToken(name, roomName).then(token => connect(token));
  };

  if (propertySummary.name == null) return null;

  const logoUrl = (propertySummary && propertySummary.logos != undefined) ? propertySummary.logos[0].src.replace('%s', '100x100') : '';

  const isToursEmpty = false;

  return (
    <AppBar className={classes.container} position="static" id="app-bar">
      <Toolbar className={classes.tourToolbar}>
        <div className={classes.logo}>
          <img src={logoUrl} /><h3>{propertySummary.name}</h3>
        </div>

        <div className={classes.tourToolbarLinks}>
          {roomState === 'disconnected' ? (
            <div></div>
          ) : (
            <Tabs 
              value={currentTab}
              indicatorColor="primary"
              onChange={handleTabChange}>
              <Tab icon={<PeopleIcon />} label="Meeting" />
              <Tab onClick={(e) => { e.preventDefault(); handleTourDrawerOpen();}} icon={<ThreeDRotationIcon />} className={clsx({ [classes.isToursActive]: isToursEmpty })} label="Tours" />
              <Tab onClick={(e) => { e.preventDefault(); handleFpDrawerOpen();}} icon={<ViewQuiltIcon />} className={clsx({ [classes.isFloorplansActive]: isFloorplansEmpty })} label="Floor Plans" />
            </Tabs>
          )}
          {/* <a href={propertySummary.webSiteUrl} className={classes.webLink} target="_blank">Visit Website</a> */}          
        </div>

        <div>
          {applyNow.applyNowUrl != null && applyNow.applyNowUrl != '' ? (<Button 
            variant="contained" 
            color="primary"
            className={classes.webLink}
            onClick={() => { window.open(applyNow.applyNowUrl, '_blank') }}>Apply Now
          </Button>) : (<span/>)}

          {propertySummary.webSiteUrl != null && propertySummary.webSiteUrl != '' ? (<Button 
            variant="contained" 
            color="primary"
            className={classes.webLink}
            onClick={() => { window.open(propertySummary.webSiteUrl, '_blank') }}>Visit Website
          </Button>) : (<span/>)}
        </div>

        <div className={classes.tourToolbarMobileLInks}>
          <IconButton aria-controls="tour-menu" aria-haspopup="true" onClick={handleClick}>
            <MenuIcon color="primary" />
          </IconButton>
          <MenuSmall
            id="tour-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={(e) => { handleMeetingButtonClick(e); handleClose();}}>Meeting</MenuItem>
            <MenuItem onClick={(e) => { e.preventDefault(); handleTourDrawerOpen(); handleClose();}} className={clsx({ [classes.isToursActive]: (isToursEmpty || roomState === 'disconnected') })}>Tours</MenuItem>
            <MenuItem onClick={(e) => { e.preventDefault(); handleFpDrawerOpen(); handleClose();}} className={clsx({ [classes.isFloorplansActive]: (isFloorplansEmpty || roomState === 'disconnected') })}>Floor Plans</MenuItem>
            {applyNow.applyNowUrl != null && applyNow.applyNowUrl != '' ? (<MenuItem onClick={(e) => { window.open(applyNow.applyNowUrl, '_blank'); handleClose();}}>Apply Now</MenuItem>) : (<span/>)}
            {propertySummary.webSiteUrl != null && propertySummary.webSiteUrl != '' ? (<MenuItem onClick={(e) => { window.open(propertySummary.webSiteUrl, '_blank'); handleClose();}}>Visit Website</MenuItem>) : (<span/>)}
          </MenuSmall>
        </div>
      </Toolbar>

      {roomState === 'connected' && <TourStrip theme={theme} tourData={propertySummary.tours} currentTour={currentTour} onTourClick={handleTourClick} onHandleDrawerClick={handleTourDrawerOpen} onDrawerClose={handleTourDrawerClose} open={tourOpen} />}
      {roomState === 'connected' && <FloorplanStrip theme={theme} currentFloorplan={currentFloorplan} propertyFloorplans={propertyFloorplans.floorplans} onFloorplanClick={handleFloorplanClick} onDrawerClose={handleFpDrawerClose} open={fpOpen} />}
    </AppBar>
  );
}
