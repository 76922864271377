import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  margin: '24px 0 0 0',
  flexDirection: 'column',
  '& > div': {
    marginBottom: '10px'
  }
});

const FilterLabel = styled(FormLabel)({
  marginRight: '10px',
  fontWeight: 'bold'
});

const FilterButtonGroup = styled(ToggleButtonGroup)({
  marginRight: '10px'
});

const FilterButton = styled(ToggleButton)({
  height: '29px',
  padding: '0 11px'
});

const useStyles = makeStyles({
  active: {
    border: '2px solid #F22F46 !important'
  },
  typesFilter: {
    flexGrow: 1,
    textAlign: 'right',
    '& div': {
      marginRight: '0'
    }
  },
  drawerBtn: {
    ['@media (min-width:768px)']: {
      display: 'none'
    },
  } 
});

interface FloorplanFilterBarProps {
  onFloorplanFiltersChange: (newValue: Array<string>) => void;
  onHandleDrawerClick: () => void;
  floorplanData: Array<any>;
  floorplanFilters: Array<string>;
}

export default function FloorplanFilterBar({ onFloorplanFiltersChange, onHandleDrawerClick, floorplanData, floorplanFilters }: FloorplanFilterBarProps) {
  const classes = useStyles();

  const [bedrooms, setBedrooms] = React.useState<string>(floorplanFilters[0]);
  const handleBedrooms = (event: React.MouseEvent<HTMLElement>, newBedrooms: string) => {
    if (newBedrooms !== null) {
      setBedrooms(newBedrooms);
      onFloorplanFiltersChange([newBedrooms, bathrooms, type]);
    }
  };

  const [bathrooms, setBathrooms] = React.useState<string>(floorplanFilters[1]);
  const handleBathrooms = (event: React.MouseEvent<HTMLElement>, newBathrooms: string) => {
    if (newBathrooms !== null) {
      setBathrooms(newBathrooms);
      onFloorplanFiltersChange([bedrooms, newBathrooms, type]);
    }
  };

  const [type, setType] = React.useState<string>('2D');
  const handleType = (event: React.MouseEvent<HTMLElement>, newType: string) => {
    if (newType !== null) {
      setType(newType);
      onFloorplanFiltersChange([bedrooms, bathrooms, newType]);
    }
  };

  return (
    <Container>
      <div>
        <FilterLabel>Beds</FilterLabel>
        <FilterButtonGroup 
          size="small" 
          value={bedrooms}
          exclusive
          onChange={handleBedrooms}
          >
          <FilterButton value="any">Any</FilterButton>
          <FilterButton value="S">S</FilterButton>
          <FilterButton value="1">1</FilterButton>
          <FilterButton value="2">2</FilterButton>
          <FilterButton value="3">3+</FilterButton>
        </FilterButtonGroup>
      </div>
      <div>
        <FilterLabel>Baths</FilterLabel>
        <FilterButtonGroup 
          size="small" 
          value={bathrooms}
          exclusive
          onChange={handleBathrooms}
          >
          <FilterButton value="any">Any</FilterButton>
          <FilterButton value="1">1</FilterButton>
          <FilterButton value="2">2+</FilterButton>
        </FilterButtonGroup>
      </div>

      <Button 
        className={classes.drawerBtn}
        variant="contained" 
        color="primary" 
        onClick={onHandleDrawerClick}>
          {floorplanData.length} Floorplan{ (floorplanData.length == 1 ? '' : 's') }
      </Button>

      {/* <IconButton onClick={handleClick}>
        <MenuIcon color="primary" />
      </IconButton> */}

      {/* <div className={classes.typesFilter}>
        <FilterButtonGroup 
          size="small" 
          value={type}
          exclusive
          onChange={handleType}
          >
          <FilterButton value="2D">2D</FilterButton>
          <FilterButton value="3D">3D</FilterButton>
        </FilterButtonGroup>
      </div> */}
    </Container>
  );
}
