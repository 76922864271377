import { useEffect, useState } from 'react';
import jsonp from 'jsonp';
import config from '../../config';

export default function useTourService(tourStr: string | undefined, status: any) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({isLeasingAgent:false,'roomDetails':{pKey:'',confirmationId:'',identity:'',roomId:'',token: ''},'appointmentDetails':{},'propertySummary':{name:null,logos:[],webSiteUrl:'',leadChannelAttributes:[]},'propertyFloorPlans':{floorplans:[]},'message':null});

  //console.log('useTourService ran', tourStr, status);

  let apptUrl = config.tsApi +  '/virtualTour/rooms/' + tourStr + '/details';
  //apptUrl = 'http://127.0.0.1:5500/' + apptUrl;

  const requestOptions = {
    method: 'GET'
  };

  useEffect(() => {
    fetch(apptUrl, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);

          if (result.status == "NOT_FOUND"){
            setError(result);
            setItems(result);
          } else if (result.status == "500"){
            setError(result);
            setItems(result);
          } else {
            setItems(result);
          }
        },
        (error) => {
          console.log('error', error);
          setIsLoaded(true);
          setError(error);
        }
      )
    
  }, [tourStr, status]);

  return items;
}
