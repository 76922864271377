import React from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//import useTourVideoSrc from '../../hooks/useTourVideoSrc/useTourVideoSrc';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface TourStripProps {
  theme: any;
  currentTour: number;
  tourData: Array<any>;
  onTourClick: (newValue: number) => void;
  onHandleDrawerClick: () => void;
  onDrawerClose: (newValue: boolean) => void;
  open: boolean;
}

export default function TourStrip({theme, currentTour, tourData, onTourClick, onHandleDrawerClick, onDrawerClose, open }: TourStripProps) {
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const handleClickAway = () => {
    setOpenDrawer(false);
    onDrawerClose(false);
  };

  const Container = styled('div')({
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '24px 0 0 0',
    alignItems: 'center',
    ['@media (max-width:767px)']: {
      justifyContent: 'space-between',
      margin: '15px',
    },
  });

  const TourContainer = styled('div')({
    width: '148px',
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    margin: '10px',
    '& p': {
      fontWeight: 'bold',
      margin: '4px 0 0',
      '& em': {
        fontWeight: 'normal'
      }
    }
  });

  const TourImageContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '4px solid transparent',
    width: '148px',
    height: '100px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    '&:hover': {
      border: '4px solid',
      borderColor: theme.palette.primary.main
    }
  });

  const useStyles = makeStyles({
    tourImg: {
      maxWidth: '100%;'
    },
    active: {
      borderColor: theme.palette.primary.main
    },
    tourDrawer: {
      width: '360px'
    },
    tourWrapper: {
      ['@media (max-width:767px)']: {
        display: 'none'
      },
    },
    drawerBtn: {
      width: '100%',
      ['@media (min-width:768px)']: {
        display: 'none'
      },
    },
    drawerWrapper: {
      width: '360px',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '12px'
    },
    toursWrapper: {
      flexWrap: 'wrap',
      display: 'flex'
    },
    closeIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: '1',
      margin: '0 !important'
    }
  });
  const classes = useStyles();
  const defualtThumb = '/img/mv_default_thumb.png';

  if (tourData == null) return null;

  const tours = tourData.map((tour, index) => {
    const tourImage = (tour.imgUrl != null) ? tour.imgUrl.replace('%s', '300x300') : defualtThumb;
    return (
      <TourContainer key={index} onClick={() => onTourClick(index)}>
        <TourImageContainer className={clsx({ [classes.active]: index == currentTour })}>
          <img src={tourImage} key={tour.src} />
        </TourImageContainer>        
        <p>{tour.caption}</p>
      </TourContainer>
    )
  })

  const toursDrawer = <ClickAwayListener onClickAway={handleClickAway}><div className={classes.drawerWrapper}><div className={classes.closeIcon}><IconButton aria-label="close" onClick={handleClickAway}><HighlightOffIcon fontSize="small" /></IconButton></div><div className={classes.toursWrapper}>{tours}</div></div></ClickAwayListener>

  return (
    <Drawer
      className={classes.tourDrawer}
      anchor="right"
      open={open}
    >
      {toursDrawer}
    </Drawer>
  );
}
