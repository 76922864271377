import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import usePublications from '../../hooks/usePublications/usePublications';
import clsx from 'clsx';
import { LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

const Container = styled('aside')(({ theme }) => ({
  width: '25vw',
  maxWidth: '260px',
  position: 'absolute',
  top: '30px',
  left: '34px',
  zIndex: 1,
  ['@media (max-width:812px)']: {
    top: '10px',
    left: '10px',
    maxWidth: '150px'
  }
}));

const ParticipantContainer = styled('div')(() => ({
  margin: '0 0 15px 0'
}));

const useStyles = makeStyles({
  hide: {
    top: '28px'
  },
  hideStrip: {
    display: 'none'
      }
});

export default function ParticipantStrip({ hideStrip, handleDisconnect }: any) {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();

  const participants = useParticipants(handleDisconnect);
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  const publications = usePublications(localParticipant);
  const isScreenShareEnabled = publications.find(p => p.trackName === 'screen');
  let isScreenShareEnabledRemote;

  participants.map((participant) => {
    var tracks = Array.from(participant.tracks.values()) as TrackPublication[];
    isScreenShareEnabledRemote = tracks.find(p => p.trackName === 'screen');
  });

  document.title = 'Video Call Tour - ' + localParticipant.identity;

  var appBarStyle = (isScreenShareEnabled) ? 'display:none' : '';
  var mainParticipantStyle = (isScreenShareEnabled) ? 'padding: 0' : '';
  var hideStrip1 = (isScreenShareEnabled) ? true : false;
  var hideStrip2 = (isScreenShareEnabledRemote) ? true : false;

  hideStrip2 = false;

  var el = document.getElementById('tab-bar');
  if (el != null){
    el.setAttribute('style', appBarStyle);
  }
  var el = document.getElementById('app-bar');
  if (el != null){
    el.setAttribute('style', appBarStyle);
  }
  var el = document.getElementById('main-participant-container');
  if (el != null){
    el.setAttribute('style', mainParticipantStyle);
  }

  return (
    <Container className={clsx({ [classes.hide]: isScreenShareEnabled }, { [classes.hideStrip]: hideStrip2 })}>
      <ParticipantContainer>
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant}
          onClick={() => null}
        />
      </ParticipantContainer>
      {participants.map(participant => (
        <ParticipantContainer>
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={selectedParticipant === participant}
            onClick={() => null}
          />
        </ParticipantContainer>
      ))}      
    </Container>
  );
}
