import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleFlipCameraButton from './ToogleFlipCameraButton/ToggleFlipCameraButton';
import ToursButton from './ToursButton/ToursButton';
import FloorplansButton from './FloorplansButton/FloorplansButton';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import useScreenShareToggle from '../../hooks/useScreenShareToggle/useScreenShareToggle';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'absolute',
    right: '50%',
    transform: 'translate(50%, 30px)',
    bottom: '10px',
    zIndex: 1,
    transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
    opacity: 0,
    visibility: 'hidden',
    '&.showControls, &:hover': {
      transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
      opacity: 1,
      visibility: 'visible',
      transform: 'translate(50%, 0px)',
    },
    ['@media (max-width:767px)']: {
      '& button': {
        width: '40px',
        height: '40px',
        '& svg': {
          fontSize: '20px',
        }
      }
    }
  },
});

export default function Controls({roomDetails, isAgent, propertySummary, propertyFloorplans, handleTourButtonClick, handleFloorplansButtonClick, handleTourDrawerOpen, handleFpDrawerOpen}: any) {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';
  const [isFlipped, isFlipAvailable, toggleFlipCamera] = useFlipCameraToggle();

  const [isScreenShared, toggleScreenShare, endScreenShare] = useScreenShareToggle();

  const handleEndScreenSharing = () => {
    endScreenShare();
  }

  return (
    <div className={clsx(classes.container, { showControls })}>
      {roomState !== 'disconnected' && (
        <>
          <ToursButton handleClick={handleTourDrawerOpen} />
        </>
      )}
      {roomState !== 'disconnected' && (
        <>
          <FloorplansButton handleClick={handleFpDrawerOpen} />
        </>
      )}
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting} />
      {isFlipAvailable && (
        <>
          <ToggleFlipCameraButton disabled={isReconnecting} />
        </>
      )}      
      {roomState !== 'disconnected' && (
        <>
          <ToggleScreenShareButton isScreenShared={isScreenShared} toggleScreenShare={toggleScreenShare} disabled={isReconnecting} />
          <EndCallButton isAgent={isAgent} propertySummary={propertySummary} propertyFloorplans={propertyFloorplans} handleEndScreenSharing={handleEndScreenSharing} />
        </>
      )}
    </div>
  );
}
