import React from 'react';
import { useParams } from 'react-router-dom';
import { styled, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CssBaseline, createMuiTheme } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import CircularProgress from '@material-ui/core/CircularProgress';
import useRoomState from './hooks/useRoomState/useRoomState';
import moment from 'moment';
import { RemoteParticipant } from 'twilio-video';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  ['@media (max-width:767px)']: {
    height: 'calc(100vh - 119px)',
  },
  position: 'relative',
  overflow: 'hidden',
  '& h2': {
    textAlign: 'center'
  }
});

const Main = styled('main')({
  height: '100%',
  position: 'relative',
  overflowY: 'scroll'
});

const DarkBackdrop = styled(Backdrop)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)'
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingSpinner: {
      margin: '50px auto',
      display: 'block',
      color: '#fff'
    },
    fab: {
      margin: theme.spacing(1),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      outline: 'none',
      '& :focus': {
        outline: 'none'
      },
      ['@media (min-width:769px)']: {
        transform: 'scale(1.5)',
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        margin: '0 10px',
        padding: '7px 30px'
      }
    },
    confirmContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
      '& button': {
        margin: '0 10px',
        padding: '7px 30px'
      },
      '& input': {
        marginRight: '5px'
      }
    }
  })
);

export default function App({tourService, applyNow, appointmentToken, setUserIdentity}:any) {
  const classes = useStyles();
  
  interface TourServiceProps {
    roomDetails: Array<any>;
    appointmentDetails: Array<any>;
    propertySummary: Array<any>;
    propertyFloorPlans: Array<any>;
  }

  const roomState = useRoomState();
  var el = document.getElementById('root');

  const [currentTab, setCurrentTab] = React.useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log('handleTabChange');
    if (newValue === 0) {
      setCurrentTab(newValue);
    }
  };

  const updateTab = (newValue: number) => {
    console.log('updateTab');
    setCurrentTab(newValue);
  };

  const handleMeetingButtonClick = (newValue: number) => {
    setCurrentTab(0);
  }

  const [tourOpen, setTourOpen] = React.useState(false);
  const handleTourDrawerOpen = () => {
    console.log('handleDrawerOpen');
    setTourOpen(true);
  };
  const handleTourDrawerClose = () => {
    console.log('handleDrawerClose');
    setTourOpen(false);
  };
  const handleTourButtonClick = (newValue: number) => {
    setCurrentTab(1);
    setTourOpen(true);
    console.log('handleTourButtonClick setOpen', tourOpen);
  }

  const [fpOpen, setFpOpen] = React.useState(false);
  const handleFpDrawerOpen = () => {
    if (currentFloorplan.id === 0) {
      setCurrentFloorplan(propertyFloorplans.floorplans[0]);
    }
    setFpOpen(true);
  };
  const handleFpDrawerClose = () => {
    setFpOpen(false);
  };
  // const handleMenuFloorplanClick = (newValue: number) => {
  //   updateTab(2);
  //   handleFloorplanClick(newValue);
  //   setFpOpen(false);
  // }

  const handleFloorplansButtonClick = (newValue: number) => {
    setCurrentTab(2);
    setFpOpen(true);
  }

  const [currentTour, setCurrentTour] = React.useState(0);
  const handleTourClick = (newValue: number) => {
    console.log('handleTourClick', newValue);
    setCurrentTab(1);
    setCurrentTour(newValue);
    setTourOpen(false);
  }

  const [currentFloorplan, setCurrentFloorplan] = React.useState({id:0});
  const handleFloorplanClick = (newValue: any) => {
    console.log('handleFloorplanClick', newValue);
    setCurrentTab(2);
    setCurrentFloorplan(newValue);
    setCurrentFloorplanImage(0);
    setFpOpen(false);
  };

  // floorplan images  
  const [currentFloorplanImage, setCurrentFloorplanImage] = React.useState(0);
  console.log('currentFloorplanImage', currentFloorplanImage);
  const handleFloorplanImageChange = (newValue: number) => {
    console.log('handleFloorplanImageChange', newValue);
    setCurrentFloorplanImage(newValue);
  }

  // tour ended
  const [open, setOpen] = React.useState(false);
  // const { room } = useVideoContext();

  const handleDisconnect = (participant: RemoteParticipant) => {
    console.log('handleDisconnect', participant);

    if (participant.identity == 'Leasing Agent') {
      setOpen(true);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // room.disconnect();
  };

  const handleSharingScreen = (newValue: boolean, remoteParticipant: boolean) => {
    //console.log('handleSharingScreen', newValue, remoteParticipant);
    if (el) el.classList.remove('remote-sharing');
    if (newValue && !remoteParticipant){
      if (tourService.propertySummary.tours != null && tourService.propertySummary.tours.length > 0){
        setCurrentTab(1);
      } else {
        setCurrentTab(2);
      }
    }
    if (newValue && remoteParticipant){
      setCurrentTab(0);
      console.log('setCurrentTab (remote-sharing)');
      if (el) el.classList.add('remote-sharing');
    }
  }

  if (appointmentToken == undefined) return <Container><h2>Invalid appointment</h2></Container>;

  const roomDetails = tourService.roomDetails;
  const appointment = tourService.appointmentDetails;
  const uiSettings = tourService.uiSettings;
  const propertySummary = tourService.propertySummary;
  const propertyFloorplans = tourService.propertyFloorPlans;
  const isAgent = tourService.isLeasingAgent;

  let tourServiceErrorMessage = '';
  if (tourService.message != null){
    if (tourService.message == 'room is expired'){
      tourServiceErrorMessage = 'Sorry, this tour has expired';
    } else {
      tourServiceErrorMessage = tourService.message;
    }
  }
  if (tourService.appointmentDetails && tourService.appointmentDetails.apptStartTime === null){
    tourServiceErrorMessage = 'Sorry, this tour does not exist';
  }

  if (tourServiceErrorMessage != '') return <Container><h2>{tourServiceErrorMessage}</h2></Container>;
  if (propertySummary.name == null) return <CircularProgress className={classes.loadingSpinner} />;
  if (moment().isAfter(moment(appointment.apptEndTime).add(2, 'hours'))) return <Container><h2>Sorry, this tour has expired</h2></Container>;

  const color = (uiSettings) ? ((uiSettings.color == '#000000' || uiSettings.color == '#000' || uiSettings.color == '') ? '#27aab9' : uiSettings.color) : '#27aab9';

  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: color,
        contrastText: '#fff',
      },
    },
    sidebarWidth: 260,
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <MenuBar theme={theme} uiSettings={uiSettings} propertySummary={propertySummary} propertyFloorplans={propertyFloorplans} roomDetails={roomDetails} applyNow={applyNow} isAgent={isAgent} currentTab={currentTab} updateTab={updateTab} handleTabChange={handleTabChange} currentTour={currentTour} currentFloorplan={currentFloorplan} handleTourClick={handleTourClick} handleTourDrawerOpen={handleTourDrawerOpen} handleTourDrawerClose={handleTourDrawerClose} handleFpDrawerOpen={handleFpDrawerOpen} handleFpDrawerClose={handleFpDrawerClose} handleMeetingButtonClick={handleMeetingButtonClick} handleFloorplanClick={handleFloorplanClick}  tourOpen={tourOpen} fpOpen={fpOpen} />
        <Main>
          {roomState === 'disconnected' ? <LocalVideoPreview theme={theme} propertySummary={propertySummary} appointment={appointment} roomDetails={roomDetails} isAgent={isAgent} setUserIdentity={setUserIdentity} /> : <Room theme={theme} propertySummary={propertySummary} propertyFloorplans={propertyFloorplans} appointment={appointment} currentTab={currentTab} currentTour={currentTour} currentFloorplan={currentFloorplan} currentFloorplanImage={currentFloorplanImage} handleFloorplanImageChange={handleFloorplanImageChange} handleTabChange={handleTabChange} roomDetails={roomDetails} isAgent={isAgent} handleSharingScreen={handleSharingScreen} handleDisconnect={handleDisconnect} />}
        </Main>
        <Controls roomDetails={roomDetails} propertySummary={propertySummary} propertyFloorplans={propertyFloorplans} isAgent={isAgent} handleTourDrawerOpen={handleTourDrawerOpen} handleFpDrawerOpen={handleFpDrawerOpen} />
        <ReconnectingNotification />
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={DarkBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Thank you for your time, your video call tour has now ended!</h2>
            <div className={classes.buttonContainer}>
              <Button 
                variant="contained" 
                size="large" 
                onClick={handleClose}>
                  Dismiss
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </MuiThemeProvider>
  );
}
